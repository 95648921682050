.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding: 14px 0;
	@include respond-to('small') {
		display: none;
	}
	& li {
		font-size: 16px;
		&::before {
			color: #fff;
		}
		& a {
			font-weight: 600;
			text-decoration: none;
			color: #000;
			font-size: 16px;
		}
	}
	& .active {
		color: #fff;
	}
}
