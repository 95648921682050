body {
	font-family: Assistant, sans-serif;
	font-weight: normal;
}

.wrapper-all-content {
	background-color: #fff;
}

.container {
	width: 100%;
	max-width: 1430px;
}

body.active {
	overflow: hidden;
}

section {
	position: relative;
	background: #fff;
}

.wrapper {
	overflow: hidden;
}

.banner_scroll_body {
	width: 100%;
	// height: 900px;
	overflow: hidden;
	#banners-scroll {
		position: static;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}

.responsive_wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	z-index: 100;
	transition: .3s;
	transform: translateX(110%);
	background: #000;
	overflow-y: auto;
	.inner {
		width: 100%;
		position: relative;
		padding: 30px 30px;
		padding-bottom: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.close_menu {
			position: absolute;
			top: 10px;
			right: 20px;
			color: #fff;
			svg {
				width: 15px;
			}
		}
		nav {
			width: 100%;
			max-width: 877px;
			margin-top: 33px;
			.main_list {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0;
				.main_li {
					position: relative;
					list-style: none;
					margin-bottom: 15px;
					&:last-child {
						margin-bottom: 0;
					}
					&:hover {
						&:before {
							width: 100%;
						}
					}
					&:before {
						content: "";
						bottom: -11px;
						left: 0;
						right: 0;
						width: 0;
						height: 1px;
						background-color: #2384b5;
						position: absolute;
						transition: .3s;
					}
					.main_link {
						color: #085082;
						font-size: 20px;
						font-weight: 700;
						text-decoration: none;
					}
					.main_link_prevent {
						display: flex;
						justify-content: center;
						align-items: center;
						svg {
							margin-right: 8px;
							width: 15px;
						}
					}
					.hidden_area {
						position: absolute;
						width: 215px;
						background-color: rgba(255, 255, 255, .9);
						padding: 40px 0;
						top: 39px;
						left: 50%;
						transform: translateX(-50%);
						z-index: 5;
						box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
						opacity: 0;
						visibility: hidden;
						transition: .3s;
						padding-top: 20px;
						ul {
							width: 100%;
							position: relative;
							padding: 0;
							li {
								width: 100%;
								padding-left: 32px;
								padding-right: 32px;
								list-style: none;
								&:nth-child(5),
								&:last-child {
									margin-bottom: 0;
								}
								a {
									color: #085082;
									font-size: 18px;
									width: 100%;
									position: relative;
									font-weight: 600;
									text-decoration: none;
									border-bottom: 1px solid #dddddd;
									padding: 18px 15px;
									text-decoration: none;
									display: flex;
								}
							}
						}
						&.active {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}
}

.responsive_wrapper.active {
	transform: none;
}

.main_block,
.slider_main_block {
	width: 100%;
}

.slider_main_block .slide {
	position: relative;
	.overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;
	}
	.title {
		width: 100%;
		text-align: center;
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: #ffffff;
		font-size: 60px;
		font-weight: 800;
        line-height: 60px;
        text-shadow: 1px 1px 1px #000;
        
	}
}

.slick-slide img{
    margin: 0 auto;
}

.about_us {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding-bottom: 70px;
	.top_img {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 2;
        bottom: 100%;
	}
	h3 {
		margin-bottom: 60px;
	}
	.top_area {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			width: 100%;
			max-width: 1034px;
			text-align: center;
			color: #000000;
			font-size: 18px;
			font-weight: 400;
			line-height: 20px;
			margin-bottom: 15px;
		}
		a {
			margin-top: 30px;
			color: #085082;
			font-size: 18px;
			font-weight: 800;
		}
	}
	ul {
		width: 100%;
		margin-top: 113px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0;
		li {
			width: 100%;
			max-width: 453px;
			position: relative;
			list-style: none;
			& a {
				display: block;
				@include link_no-hover(#fff);
			}
			figure {
				width: 100%;
				.main_photo {
					width: 100%;
					object-fit: cover;
				}
			}
			.text {
				width: calc(100% - 43px);
				bottom: 25px;
				right: 15px;
				background-color: rgba(0, 0, 0, .4);
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				height: 100px;
				p {
					width: 100%;
					text-align: center;
					color: #ffffff;
					font-size: 40px;
					font-weight: 800;
					margin-bottom: 0;
				}
			}
		}
	}
}

h3 {
	width: 100%;
	text-align: center;
	color: #085082;
	font-size: 35px;
	font-weight: 800;
	padding-bottom: 40px;
	border-bottom: 1px solid #d6d6d6;
	margin-top: 0;
}

.projects_wrap {
	width: 100%;
	background: url('../images/back5.jpg') top center no-repeat;
	background-size: cover;
	padding: 100px 0;
	h3 {
		border-color: #ffffff;
		margin-bottom: 100px;
	}
	.slider_wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		.dotted_line {
			position: absolute;
			top: 336px;
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
		}
		.slider {
			width: 100%;
			max-width: 1232px;
			position: relative;
			z-index: 2;
			.inside {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.top_img {
					margin-bottom: 17px;
				}
				.circle {
					margin-bottom: 27px;
				}
				.text {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					p {
						width: 100%;
						text-align: center;
						margin-bottom: 0;
						color: #000000;
						font-size: 18px;
						font-weight: 400;
						line-height: 20px;
						max-width: 263px;
						&.title {
							margin-bottom: 20px;
							color: #085082;
							font-size: 25px;
							font-weight: 800;
							line-height: 20px;
						}
					}
				}
			}
		}
		.arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 322px;
		} 
	}
}

.block {
	display: block;
	@include link_no-hover(#000); 
}

.news_wrap {
	width: 100%;
	padding-top: 100px;
	padding-bottom: 190px;
	position: relative;
	.top_flag {
		position: absolute;
		top: -57px;
		left: -36px;
		z-index: 2;
	}
	h3 {
		margin-bottom: 100px;
	}
	.slider {
		width: 100%;
		.slide {
			margin-right: 13px;
		}
		.inside {
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 100%;
				height: 362px;
				object-fit: cover;
			}
			.block {
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 2;
				padding: 20px 5px;
				background-color: rgba(0, 0, 0, 0.4);
				p {
					width: 100%;
					margin-bottom: 0;
					text-align: center;
					color: #ffffff;
					font-size: 18px;
					font-weight: 400;
				}
				.title {
					color: #ffffff;
					font-size: 25px;
					font-weight: 800;
					margin-bottom: 5px;
				}
			}
		}
		.slick-dots {
			position: absolute;
			left: 50%;
			bottom: -59px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			margin: 0;
			li {
				list-style: none;
				margin: 0 2px;
				&.slick-active {
					button {
						background-color: #2384b5;
					}
				}
				button {
					border: none;
					text-indent: -10000;
					font-size: 0;
					transition: .3s;
					width: 12px;
					height: 12px;
					background-color: #dddddd;
					padding: 0;
					margin: 0;
					border-radius: 50%;
				}
			}
		}
	}
}

main {
	z-index: 2;
}

.logos_wrap {
	width: 100%;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
	background-color: #ffffff;
	.inside_cont {
		position: relative;
		.arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		.arrow_right {
			right: -60px;
		}
		.arrow_left {
			left: -60px;
		}
	}
	.slide {
		figure {
			height: 149px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

// PROJECTS PAGE

.main_back {
	width: 100%;
	position: relative;
	height: 450px;
	background: url('../images/back7.jpg') top center no-repeat;
	background-size: cover;
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: #000000;
		opacity: 0.3;
	}
	.container {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		bottom: 0;
		padding-bottom: 13px;
		.breadcrumbs {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			padding: 0;
			margin: 0;
			li {
				font-size: 18px;
				font-weight: 600;
				color: #fff;
				margin-left: 20px;
				list-style: none;
				&:last-child {
					margin: 0;
				}
				a {
					font-size: 18px;
					font-weight: 600;
					color: #000;
					text-decoration: none;
					position: relative;
					&:before {
						content: "/";
						position: absolute;
						left: -10px;
						top: 0px;
						font-size: 18px;
						font-weight: 600;
						color: #000;
					}
				}
			}
		}
	}
}

.projects_wrap2 {
	background: #fff;
	padding-bottom: 80px;
	h3 {
		border-color: #d6d6d6;
		margin-bottom: 60px;
	}
	.slider_wrap {
		justify-content: center;
	}
	.slider {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.slide {
			margin-bottom: 66px;
		}
	}
	.slider_wrap .dotted_line {
		top: 326px;
	}
}


@media screen and (max-width: 1300px) {
	.slider_main_block .slide .title {
		font-size: 40px;
		top: 40%;
		line-height: 30px;
	}
	.banner_scroll_body {
        // height: 600px;
        
		.slide_img {
			// height: 700px;
		}
	}
	.about_us h3 {
		font-size: 30px;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}
	.about_us ul {
		margin-top: 60px;
	}
	.about_us ul li .text p {
		font-size: 25px;
	}
	.about_us ul li .text {
		height: 75px;
	}
	.about_us {
		padding-bottom: 40px;
	}
	.projects_wrap {
		padding: 40px 0;
	}
	.projects_wrap h3 {
		margin-bottom: 30px;
	}
	.projects_wrap .slider_wrap .dotted_line,
	.news_wrap .top_flag {
		display: none;
	}
	.news_wrap {
		padding-top: 40px;
		padding-bottom: 80px;
		h3 {
			padding-bottom: 20px;
			margin-bottom: 30px;
		}
	}
	.projects_wrap .slider_wrap .slider {
		max-width: calc(100% - 60px);
	}
}

@media screen and (max-width: 991px) {
    .banner_scroll_body {
        margin-top: 70px;
    }
	.about_us ul {
		flex-direction: column;
		align-items: center;
		li {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.projects_wrap2 .slider .slide {
		width: 100%;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 650px) {
	.slider_main_block .slide .title {
		font-size: 30px;
	}
	.projects_wrap .slider_wrap .slider {
		max-width: 100%;
	}
	.projects_wrap .slider_wrap .arrow {
		display: none;
	}
	.news_wrap .slider .slide {
		margin-right: 0;
	}
}
