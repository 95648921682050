.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 450px;
	@include respond-to('small') {
		min-height: inherit;
	}
}

.banner-top-content__image {
	position: relative;
	background-position: center center;
	background-size: cover;
	height: 450px;
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.3);
	}
}

.banner-bread {
	z-index: 2;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	max-width: 1430px;
	padding: 0 15px;
	width: 100%;
}