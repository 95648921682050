.teams {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	margin: 0 -8px;
	&__item {
		padding: 8px;
		width: 33.33%;
		min-width: 290px;
	}
}

.team-item {
	height: 330px;
	max-width: 330px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	&__text {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		background-color: rgba($color: #000000, $alpha: 0.4);
		padding: 10px;
		text-align: center;
		color: #fff;
	}
	&__title {
		margin-bottom: 10px;
		font-size: 20px;
		font-weight: 900;
		line-height: 1;
	}
	&__desc {
		max-width: 215px;
		width: 100%;
		margin: 0 auto;
		font-size: 16px;
		font-weight: 400;
		line-height: 1;
	}
}