.horizontal-form {
	position: relative;
	z-index: 1;
	padding: 25px 0 5px;
	background-color: #0e0b4f;
	background-image: linear-gradient(to top, #0e0b4f 0%, #003a74 100%);
	@include respond-to('large') {
		padding: 20px 0 5px;
	}
}

.section-limit {
	&_form {
		position: relative;
		z-index: 2;
		max-width: 1430px;
		width: 100%;
	}
}

.form-horizontal {
	& .contact-us-form {
		overflow: hidden;
	}
	& .contact-us-form__title {
		margin-bottom: 20px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		color: #fff;
		line-height: 1;
		@include respond-to('1100') {
			font-size: 20px;
		}
		@include respond-to('small') {
			font-size: 18px;
		}
		& span {
			white-space: nowrap;
			font-size: 20px;
			font-weight: 900;
			@include respond-to('small') {
				font-size: 18px;
			}
		}
	}
	& .contact-wrap-input {
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 20px;
		max-width: 240px;
		width: 100%;
		@include respond-to('larger') {
			// max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
			font-size: initial;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		// &_check {
		// 	max-width: 108px;
		// 	@include respond-to('larger') {
		// 		max-width: 40%;
		// 		margin: 0;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		margin-top: 2px;
		// 		max-width: 44%;
		// 	}
		// }
		&_ml {
			margin-#{$left}: 0;
		}
		&_ta {
			max-width: 285px;
			@include respond-to('huge') {
				max-width: 240px;
			}
			@include respond-to('large') {
				margin-bottom: 10px;
			}
		}
		&_select {
			@include respond-to('medium') {
				padding: 0 8px 10px;
			}
			@include respond-to('extra-small') {
				padding: 0 4px 10px;
			}
			& select {
				width: 100%;
				height: 45px;
				color: #4f4f4f;
				padding: 0px 10px 0px;
				font-size: 16px;
				& option {
					color: #4f4f4f;
					font-size: 16px;
				}
			}
		}
		&_button {
			max-width: 200px;
			float: $left;
			margin: 0;
			// margin-#{$left}: 30px;
			@include respond-to('huge') {
				// max-width: 160px;
			}
			@include respond-to('larger') {
				display: block;
				margin: 0 auto;
				max-width: 240px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				// margin-top: 10px;
				// max-width: 44%;
				// min-width: 260px;
			}
		}
		&_check {
			display: block;
			margin-top: 8px;
			max-width: none;
			@include respond-to('small') {
				& .ftr-check-uncheck {
					margin-bottom: 5px;
				}
			}
		}
	}
	& .newslatter-input {
		height: 45px;
		width: 100%;
		padding: 11px 10px 10px;
		// height: 100%;
		background-color: #fff;
		color: #4f4f4f;
		font-weight: 300;
		box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.1);
		font-size: 16px;
		border: none;
		border-radius: 0;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			font-size: 16px;
			color: #4f4f4f;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 45px;
		font-size: 18px;
		font-weight: 900;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
		background-color: #f6f6f6;
		border: none;
		color: #000;
		&:active {
			@include btn-effect-active;
		}
	}
}

.help-block {
	font-size: 16px;
	color: #fff !important;
}

.form-control:focus {
	border-radius: 0;
}

.ftr-check-uncheck {
	position: relative;
	display: inline-block;
	width: 19px;
	height: 19px;
	margin-#{$left}: 5px;
	// margin-top: 5px;
	vertical-align: top;
	top: 3px;
	label {
		width: 19px;
		height: 19px;
		cursor: pointer;
		position: absolute;
		background-color: #fff;
		border: 1px solid #bebebe;
		box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.1);
		#{$left}: 0;
		top: 0;
		&:after {
			content: '';
			width: 12px;
			height: 6px;
			position: absolute;
			top: 4px;
			#{$left}: 3px;
			border: 2px solid #000;
			border-top: none;
			border-right: none;
			background: 0 0;
			opacity: 0;
			transform: rotate(-45deg);
		}
	}
	input[type=checkbox] {
		visibility: hidden;
		&:checked + label::after {
			opacity: 1;
		}
	}
	&_side {
		margin-top: 20px;
		label::after {
			top: -2px;
		}
	}
}

.ftr-checkbox-para {
	display: inline-block;
	// max-width: 75px;
	@include respond-to('larger') {
		// max-width: 150px;
	}
	& > p {
		font-size: 18px;
		font-weight: 400;
		color: #fff;
		margin: 0;
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
	&_side {
		margin-top: 15px;
		max-width: 128px;
	}
}

.bottom-info {
	margin-top: 20px;
	padding: 14px 0;
	border-top: 1px solid #4555be;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	& p {
		margin: 0;
	}
}