@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/variables-base64',
  'abstracts/mixins';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/accessibility/accessibility.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/breadcrumb/breadcrumb.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/horizontal-form/horizontal-form.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/inner-banner/inner-banner.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/popap-search/popap-search.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/scroll-down/scroll-down.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/side-menu/side-menu.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/side-navigation/side-navigation.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/site-footer/site-footer-credit/site-footer-credit.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/site-footer/site-footer-form/site-footer-form.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/site-footer/site-footer-links/site-footer-links.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/site-footer/site-footer.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/site-header/site-header.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/blocks_shared/social-share/social-share-btns.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/layout/base-layout/base-layout.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/layout/page-layout/page-layout.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/layout/page-with-side-layout/page-with-side-layout.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/article/article-item.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/article/article-page.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/blog/blog-category-box/blog-category-box.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/blog/blog-category.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/blog/blog-item-box/blog-item-box.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/blog/blog-item.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/contact-us/form-elements.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/contact-us/landing/landing.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/content/content-page.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/homepage/homepage-slider/homepage-slider.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/homepage/homepage.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/projects/projects-page.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/search/search.scss";
@import "E:/xampp/htdocs/_projects_simplysmart/atidais/app/resources/pages/team/team-page.scss";
