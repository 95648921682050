.side-navigation-wrap {
	position: relative;
	z-index: 1;
	max-width: 300px;
	width: 100%;
	padding: side-values(80px 0 50px 0);
	@include respond-to('large') {
		padding: side-values(50px 0 30px 0);
	}
}

.side-nav-menu {
	&__title {
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 900;
		color: $accent;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-image: linear-gradient(to right, rgba(8, 80, 130, 0) 0%, rgba(8, 80, 130, 0.21) 19%, rgba(8, 80, 130, 0.5) 41%, rgba(8, 80, 130, 1) 100%);
		}
	}
	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		& li {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-image: linear-gradient(to right, rgba(190, 190, 190, 0) 0%, rgba(190, 190, 190, 0.21) 19%, rgba(190, 190, 190, 0.5) 41%, rgba(190, 190, 190, 1) 100%);
			}
			&.active {
				&::after {
					background-image: linear-gradient(to right, rgba(8, 80, 130, 0) 0%, rgba(8, 80, 130, 0.21) 19%, rgba(8, 80, 130, 0.5) 41%, rgba(8, 80, 130, 1) 100%);
				}
				& a {
					font-weight: 900;
					color: $accent;
				}
			}
			& a {
				display: block;
				padding: 18px 0;
				font-size: 20px;
				font-weight: 400;
				color: #7d7d7d;
				transition: color 0.2s ease;
				@include respond-to('medium') {
					padding: 10px 0;
					font-size: 17px;
				}
				&:hover,
				&:focus, 
				&:active {
					text-decoration: none;
				}
			}
		}
	}
}