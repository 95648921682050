.noslider {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	@include respond-to('larger') {
		justify-content: space-evenly;
	}
	&__item {
		padding: 8px;
		width: 25%;
		min-width: 300px;
	}
	.inside {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 100%;
			height: 362px;
			object-fit: cover;
		}
		.block {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 2;
			padding: 15px 5px;
			background-color: rgba(0, 0, 0, 0.4);
			p {
				width: 100%;
				margin-bottom: 0;
				text-align: center;
				color: #ffffff;
				font-size: 18px;
				font-weight: 400;
			}
			.title {
				color: #ffffff;
				font-size: 25px;
				font-weight: 800;
				margin-bottom: 0px;
			}
		}
	}
	.slick-dots {
		position: absolute;
		left: 50%;
		bottom: -59px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			margin: 0 2px;
			&.slick-active {
				button {
					background-color: #2384b5;
				}
			}
			button {
				border: none;
				text-indent: -10000;
				font-size: 0;
				transition: .3s;
				width: 12px;
				height: 12px;
				background-color: #dddddd;
				padding: 0;
				margin: 0;
				border-radius: 50%;
			}
		}
	}
}