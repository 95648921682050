#staff-content p{
    line-height: 16px;
    margin-bottom: 5px;
}  
.write-h{
    @include respond-to('medium'){
       min-height: 85px !important;
    }
}

.tstaf1, .tstaf2{
    min-height: 20px;
}
.textstaf{
    min-height: 60px; 
}
.write-h {
    margin-top: 25px;
} 