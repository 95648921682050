.wrapper-all-content {
	&_cont {}
}

.wrap-content {
	padding: 80px 0 25px;
	@include respond-to('large') {
		padding: 50px 0 15px;
	}
	@include respond-to('small') {
		padding: 20px 0 15px;
	}
	@include respond-to('extra-small') {
		padding: 15px 0;
	}
	&_shadow {
		min-height: 70vh;
		height: 100%;
		width: 100%;
		padding-#{$right}: 75px;
		@include respond-to('1100') {
			padding-#{$right}: 0;
		}
		@include respond-to('medium') {
			min-height: auto;
			box-shadow: none;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.content-str {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		#{$right}: 20.5%;
		height: 100%;
		width: 40px;
		background-color: transparent;
		box-shadow: if-rtl(-25px 0 15px 0 rgba(0, 0, 0, 0.04), 25px 0 15px 0 rgba(0, 0, 0, 0.04));
		@include respond-to('1100') {
			#{$right}: 21%;
		}
		@include respond-to('medium') {
			display: none;
		}
	}
	&__right {
		position: relative;
		width: 24%;
		float: $right;
		padding-#{$left}: 48px;
		@include respond-to('larger') {
			padding-#{$left}: 30px;
		}
		@include respond-to('1100') {
			width: 26%;
			padding-#{$left}: 15px;
		}
		@include respond-to('medium') {
			display: none;
		}
	}
	&__left {
		float: $left;
		width: 76%;
		height: 100%;
		padding-bottom: 50px;
		@include respond-to('huge') {
			// width: 75%;
		}
		@include respond-to('1100') {
			padding-#{$right}: 15px;
			width: 74%;
		}
		@include respond-to('medium') {
			width: 100%;
			padding-#{$right}: 0;
			padding-bottom: 10px;
		}
	}
}

.title-main {
	position: relative;
	font-size: 35px;
	font-weight: 900;
	color: $accent;
	margin: 0 0 55px;
	padding-bottom: 20px;
	border-bottom: 1px solid #c4c4c4;
	@include respond-to('small') {
		margin: 0 0 25px;
		padding-bottom: 15px;
		font-size: 25px;
	}
	&_center {
		text-align: center;
	}
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	width: 100%;
	// 	height: 1px;
	// 	background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.21) 19%, rgba(0, 0, 0, 0.5) 41%, rgba(0, 0, 0, 1) 100%);
	// }
	&_mb0 {
		margin-bottom: 0;
	}
}

.content-text {
	&_gal {
		margin: 0 auto;
		max-width: 900px;
		text-align: center;
	}
	h2,
	h3 {
		font-size: 25px;
		font-weight: 700;
		line-height: 1.2;
		color: $text;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 22px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.4;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	ul {
		list-style: none;
		font-size: 18px;
		font-weight: 400;
		color: $text;
		line-height: 1.4;
		padding: 0;
		& li {
			position: relative;
			padding-#{$right}: 20px;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 8px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $accent;
			}
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}