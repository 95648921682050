.header-desktop {

	@include respond-to('medium') {
		display: none;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		background-color: #fff;
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 2px;
		float: $left;  
		& > img {
			width: 120px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.mob_menu__button {
	width: 24px;
	background: transparent;
	border: none;
	padding: 0;
	margin: 0;
	display: none;
}

.mob_menu__button span {
	width: 100%;
	float: left;
	display: block;
	height: 2px;
	border-radius: 1px;
	background-color: #000;
	position: relative;
	transition: .2s;
}

.mob_menu__button span:not(:last-child) {
	margin-bottom: 5px;
}

header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding-top: 11px;
	z-index: 5;
	transition: .3s;
	@include respond-to('medium') {
		padding: 0;
	}
	&.active {
		box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
		background-color: rgba(255, 255, 255, .9);
		padding: 4px 0;
		@include respond-to('medium') {
			padding: 0;
		}
		.back {
			opacity: 0;
			visibility: hidden;
		}
		.logo {
			img {
				max-width: 143px;
			}
		}
		nav {
			margin-top: 0;
		}
		.inside_cont {
			align-items: center;
		}
	}
	.container {
		position: relative;
		z-index: 2;
	}
	.back {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		height: 304px;
	}
	.inside_cont {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	nav {
		width: 100%;
		max-width: 877px;
		margin-top: 33px;
		.main_list {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0;
			.main_li {
				position: relative;
				list-style: none;
				&:hover {
					&:before {
						width: 100%;
					}
				}
				&:before {
					content: "";
					bottom: -11px;
					left: 0;
					right: 0;
					width: 0;
					height: 1px;
					background-color: #2384b5;
					position: absolute;
					transition: .3s;
				}
				.main_link {
					color: #085082;
					font-size: 20px;
					font-weight: 700;
					text-decoration: none;
				}
				.hidden_area {
					position: absolute;
					width: 215px;
					background-color: rgba(255, 255, 255, .9);
					padding: 40px 0;
					top: 39px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 5;
					box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
					opacity: 0;
					visibility: hidden;
					transition: .3s;
					padding-top: 20px;
					ul {
						width: 100%;
						position: relative;
						padding: 0;
						li {
							width: 100%;
							padding-left: 32px;
							padding-right: 32px;
							list-style: none;
							&:nth-child(5),
							&:last-child {
								margin-bottom: 0;
							}
							a {
								color: #085082;
								font-size: 18px;
								width: 100%;
								position: relative;
								font-weight: 600;
								text-decoration: none;
								border-bottom: 1px solid #dddddd;
								padding: 18px 15px;
								text-decoration: none;
								display: flex;
							}
						}
					}
					&.active {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 1200px) {
	nav {
		.main_list .main_li {
			&:hover {
				.hidden_area {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	header {
		.inside_cont {
			align-items: center;
		}
		nav {
			margin-top: 0;
		}
		.logo {
			img {
				max-width: 200px;
			}
		}
	}
}

