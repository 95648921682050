.new-gallery {
	margin: 0 -8px;
	padding-bottom: 50px;
	padding-top: 30px;
	@include respond-to('medium') {
		padding-bottom: 25px;
		margin: 0px -2px;
	}
}

.gallery-item-wrap {
	width: 66.66%;
	float: $right;
	padding: 5px;
	@include respond-to('medium') { 
		width: 33.33%;
		padding: 2px;
	}
	@include respond-to('small') {
		width: 50%;
	}
	@include respond-to('extra-small') {
		float: none;
		width: 100%;
		max-width: 320px;
		margin: 0 auto;
	}
	&:nth-child(4n + 2) {
		width: 33.33%;
		@include respond-to('medium') {}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
	&:nth-child(4n + 3) {
		width: 33.33%;
		@include respond-to('medium') {}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
	&:hover {
		.gallery-item__img {
			transform: scale3d(1.1, 1.1, 1.1);
		}
		.gallery-item__overlay {
			opacity: 1;
		}
	}
}

.gallery-item {
	position: relative;
	display: block;
	overflow: hidden;
	height: 350px;
	text-align: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('larger') {
		height: 300px;
	}
	@include respond-to('medium') {
		width: 100%;
		height: 250px;
	}
	@include respond-to('extra-small') {
		width: 100%;
		height: 200px;
	}
	&__img {
		opacity: 0;
		visibility: hidden;
		// display: block;
		// max-width: 100%;
		// height: 100%;
		// width: 100%;
		// transform: scale3d(1, 1, 1);
		// transition: transform 0.15s ease 0s;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: $accent, $alpha: 0.8);
		opacity: 0;
		transition: opacity 0.15s ease 0s;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& i {
			font-size: 40px;
			color: #fff;
		}
	}
	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;
	}
}