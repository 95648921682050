footer {
	width: 100%;
	background: url('../images/back6.jpg') top center no-repeat;
	background-size: cover;
	padding-top: 115px;
	position: relative;
	z-index: 1;
	.top_area {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			width: 100%;
			text-align: center;
			margin-bottom: 0;
			color: #000000;
			font-size: 20px;
			font-weight: 400;
			&.title {
				color: #2384b5;
				font-size: 35px;
				font-weight: 800;
				line-height: 25px;
			}
		}
		form {
			width: 100%;
			margin-top: 55px;
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 721px;
			.inputs_cont {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.right_cont {
					width: 100%;
					max-width: 331px;
					input {
						width: 100%;
						border: none;
						background: none;
						border-bottom: 1px solid #2384b5;
						margin-bottom: 20px;
						height: 50px;
						color: #085082;
						font-size: 20px;
						font-weight: 400;
						&::placeholder {
							color: #085082;
							font-size: 20px;
							font-weight: 400;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				textarea {
					width: 100%;
					max-width: 350px;
					border: none;
					background: none;
					border-bottom: 1px solid #2384b5;
					height: 185px;
					color: #085082;
					font-size: 20px;
					font-weight: 400;
					resize: none;
					&::placeholder {
						color: #085082;
						font-size: 20px;
						font-weight: 400;
					}
				}
			}
			button {
				width: 100%;
				margin-top: 50px;
				height: 60px;
				display: flex;
				max-width: 190px;
				justify-content: center;
				align-items: center;
				color: #ffffff;
				font-size: 18px;
				font-weight: 800;
				border: none;
				box-shadow: 5px 9px 30px rgba(0, 0, 0, 0.15);
				background-image: linear-gradient(-230deg, #2384b5 0%, #085082 100%);
			}
		}
	}
	.bottom_area {
		width: 100%;
		margin-top: 220px;
		.top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 77px;
			border-bottom: 1px solid #ffffff;
			margin-bottom: 22px;
			.right {
				width: 100%;
				max-width: 446px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				ul {
					width: 100%;
					max-width: 200px;
					padding: 0;
					li {
						width: 100%;
						margin-bottom: 15px;
						list-style: none;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;
							color: #ffffff;
							font-size: 18px;
							font-weight: 800;
							img {
								margin-left: 15px;
							}
						}
					}
				}
			}
			.left {
				width: 100%;
				max-width: 812px;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				ul {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 0;
					li {
						list-style: none;
						a {
							color: #ffffff;
							font-size: 18px;
							font-weight: 400;
							line-height: 25px;
						}
					}
				}
			}
		}
		.bottom {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 26px;
		}
	}
}



// MEDIA QUERIES

@media screen and (max-width: 1300px) {
	footer {
		padding-top: 40px;
	}
	footer .bottom_area {
		margin-top: 60px;
	}
}

@media screen and (max-width: 991px) {
	footer .bottom_area .top {
		flex-direction: column;
		align-items: center;
		padding-bottom: 20px;
	}
	footer .bottom_area .top .right {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 770px) {
	footer .top_area form .inputs_cont {
		flex-direction: column;
		align-items: center;
	}
	footer .top_area form .inputs_cont .right_cont {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 540px) {
	footer .bottom_area .top .right {
		flex-direction: column;
		align-items: center;
	}
	footer .bottom_area .top .right ul {
		margin-top: 20px;
	}
	footer .bottom_area .top .left {
		flex-direction: column;
		align-items: center;
	}
}
